export const sidebar = {
  order: 0,
  name: "Sidebar",
  keywords: "side menu secondary panel",
  subcategory: "Navigating Tower Hunt",
  markdown: `# Sidebar

  The sidebar displays a variety of information intended to support your main view. You'll see horizontal and vertical tabs for navigation. The sidebar can be resized, relocated or even hidden to suit your needs.

  ![Sidebar screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Horizontal tabs

  The sidebar can display different categories of information. Major categories appear as tabs arranged from left to right across the top of the sidebar. Some tabs are always visible, while others are situational. Refer to the table below for more detail about each horizontal tab.

  | **Tab name** | **Used for** | **Always visible?** |
  |---|---|---|
  | {% inlineRouterLink articleId="me-tab" %}**Me**{% /inlineRouterLink %} | Real estate info specific to you, such as {% inlineRouterLink %}news{% /inlineRouterLink %}, {% inlineRouterLink %}tasks{% /inlineRouterLink %}, {% inlineRouterLink %}contributions{% /inlineRouterLink %}, and {% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} | Yes |
  | {% inlineRouterLink articleId="contacts-tab" %}**Contacts**{% /inlineRouterLink %} | Info focused on people, such as {% inlineRouterLink %}teams{% /inlineRouterLink %}, {% inlineRouterLink %}nearby companies{% /inlineRouterLink %}, and {% inlineRouterLink %}property diagram contacts{% /inlineRouterLink %} | Yes |
  | **Sign in** | Accessing Tower Hunt via {% inlineRouterLink %}sign up{% /inlineRouterLink %}, {% inlineRouterLink %}sign in{% /inlineRouterLink %}, and {% inlineRouterLink %}password reset{% /inlineRouterLink %} | No |
  | {% inlineRouterLink %}**My Account**{% /inlineRouterLink %} | Managing your account settings | No |
  | {% inlineRouterLink articleId="understand-validation" %}**Validations**{% /inlineRouterLink %} | Publishing datapoints, either by {% inlineRouterLink %}voting on available validations{% /inlineRouterLink %} or {% inlineRouterLink %}declassifying your Safezone datapoints{% /inlineRouterLink %} and {% inlineRouterLink %}adding citations{% /inlineRouterLink %} | No |
  | {% inlineRouterLink articleId="the-role-of-the-analyze-tab" %}**Analyze**{% /inlineRouterLink %} | Analysis of collections of {% inlineRouterLink %}datapoints{% /inlineRouterLink %} | No |
  | {% inlineRouterLink articleId="deal-hub" %}**Deal Hub**{% /inlineRouterLink %} | Creating and researching {% inlineRouterLink %}investments{% /inlineRouterLink %} | No |
  | {% inlineRouterLink articleId="details-tab" %}**Details**{% /inlineRouterLink %} | Creating and researching datapoints that are linked to whatever is selected in a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}, {% inlineRouterLink %}company page{% /inlineRouterLink %}, or {% inlineRouterLink %}contact page{% /inlineRouterLink %} | No |
  | {% inlineRouterLink %}**Help**{% /inlineRouterLink %} | Getting help while using Tower Hunt | No |

  ## Resize sidebar

  The sidebar is separated from the primary panel by a thick purple border. Drag the border to resize the sidebar.

  ![Resize sidebar GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Change layout

  The sidebar can be displayed in either a side-by-side or a top-and-bottom orientation. It can also be hidden, making the primary panel take up the entire screen. Use the {% inlineRouterLink articleId="key-actions-hub" sectionName="Layout selector" %}layout selector{% /inlineRouterLink %} in the {% inlineRouterLink articleId="key-actions-hub" %}Key Actions hub{% /inlineRouterLink %} to select from these three options.`,
};
